// Site Title
.site-title {
  padding-top: 15px;
  text-align: center;
}

// Site links
.MuiDrawer-paper span {
  text-align: center;
  font-family: "Big Shoulders Display";
  text-transform: uppercase;
  font-size: 28px;
}
// Letter spacing
.title-container .MuiTypography-subtitle1,
.MuiDrawer-paper span {
  letter-spacing: 2px;
}

// Drawer toggle spacing
.makeStyles-toolbar-13 {
  margin: 5px 0;
}

// Disable highlighting of site title
.title-container {
  user-select: none;
}

// Collapsed styles
.collapsed {
  // Site title container
  .site-title {
    padding: 0;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  // Avatar
  .MuiAvatar-circle {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }
  // Name/Role
  .title-text {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
  }
  h1.MuiTypography-h1 {
      font-size: 36px;
  }
  h6.MuiTypography-subtitle1 {
    font-size: 18px;
  }
  // Social links
  p.st-links.MuiTypography-body1 {
    display: flex;
    flex-direction: column;
  }
  // Rotate nav links
  .MuiListItem-button span {
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
  }
  .st-links {
    margin-top: 15px;
  }
}

// 600px drawer breakpoint
@media (max-width: 599px) {
  // Disable collapse toggle
  .MuiDivider-root,
  .collapse-btn {
    display: none !important;
  }
  // Font size collapsed scaling
  .collapsed {
    h1.MuiTypography-root.MuiTypography-h1 {
      font-size: 28px;
    }
    h6.MuiTypography-root.MuiTypography-subtitle1 {
      font-size: 14px;
    }
    .MuiListItem-button span {
      font-size: 20px;
      line-height: 120%;
    }
  }
}