.tech-scroll {
  background: #0f0f0f;
  border: 3px solid black;
  list-style-type: none;
  margin: 0 0 10px 0; padding: 0;
  border-radius: 5px;
  li {
    color: #149414;
    display: inline-block;
    margin: 5px 10px;
  }
}